import { createTheme, DEFAULT_THEME, MantineColorsTuple, getSize, mergeMantineTheme, Container, VariantColorsResolver, defaultVariantColorsResolver, parseThemeColor, rgba, darken } from '@mantine/core';
import { themeToVars } from '@mantine/vanilla-extract';

// https://mantine.dev/styles/variants-sizes/
const variantColorResolver: VariantColorsResolver = (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input);
    const parsedColor = parseThemeColor({
      color: input.color || input.theme.primaryColor,
      theme: input.theme,
    });
  
    // Can also reference colors like 'var(--mantine-color-black)'

    if (input.variant === 'light') {
      return {
        ...defaultResolvedColors,
        background: rgba(parsedColor.value, 0.4),
        hover: rgba(parsedColor.value, 0.55),
        color: darken(parsedColor.value, 0.1),
      };
    }
  
    return defaultResolvedColors;
  };

const themeOverride = createTheme({
    primaryColor: 'si',
    fontFamily: 'Outfit, sans-serif',
    headings: {
        sizes: {
            h1: {
                fontSize: '3rem',
            },
            h2: {
                fontSize: '2.75rem',
            },
            h3: {
                fontSize: '2.2rem',
            },
            h4: {
                fontSize: '1.8rem',
            },
            h5: {
                fontSize: '1.65rem',
            },

        }
    },
    components: {
        Container: Container.extend({
            vars: (_, { size }) => ({
                root: {
                    '--container-size': size ? getSize(size, 'container-size') : '1280px',
                },
            }),
        }),
        Title: {
            defaultProps: {
                style: { color: 'var(--mantine-color-logoBlue-5)' },
            }
        },
    },
    fontSizes: {
        'lg': '1.25rem',
    },
    white: '#FCFCFF',

    colors: {
        
        gray: [
            '#718096',
            '#FCFCFF',
            ...DEFAULT_THEME.colors.gray.slice(1),
        ] as unknown as MantineColorsTuple, // ts doesn't type slice correctly

        'si': [
            "#e6ffff",
            "#ccffff",
            "#b3ffff",
            "#99ffff",
            "#80ffff",
            "#66ffff",
            "#00cccc", // primary
            "#009999", // primary hover
            "#008080",
            "#006666",
            "#004d4d"
        ],

        logoBlue: [
            "#cce6ff",
            "#99ccff",
            "#66b3ff",
            "#3399ff",
            "#0080ff",
            "#00205B", // the logo color
            "#001747",
            "#001333",
            "#000f1f",
            "#000a0a",
            "#000000"
          ],
    },
    other: {
        disableMotion: false,
    },
    variantColorResolver: variantColorResolver,
});


export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);


// CSS variables object, can be access in *.css.ts files
export const vars = themeToVars(theme);
