// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
//import '@mantine/core/styles.css';
import './src/styles/global.scss';

import React from 'react';
import { MantineProvider } from '@mantine/core';
import { theme } from './src/theme';

export const wrapPageElement = ({ element }) => {
  return <MantineProvider theme={theme}>{element}</MantineProvider>;
};
